<template>
  <div>
    <b-overlay :show="loading">
      <table-data-widget :columns="cColumns" :items="items" view-able @clickView="tableClickView" />
      <!-- <pre>{{ items }}</pre> -->
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    keyName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      statusSettingInfoText: {
        'Wait Document': 'รอเอกสารยืนยันตัวตน',
        Submit: 'รออนุมัติ',
        Approval: 'อนุมัติ',
        Reject: 'ไม่อนุมัติ',
      },
      statusAgreementInfo: {
        Pending: 'รอเอกสารยืนยันตัวตนอนุมัติ',
        Approval: 'อนุมัติ',
        Reject: 'ไม่อนุมัติ',
        'Create document contract': 'รอครีเอเตอร์เซ็นเอกสารสัญญา',
        'Pending document contract': 'รอแอดมินอนุมัติเอกสารสัญญา',
      },
    }
  },
  computed: {
    cColumns() {
      if (this.keyName === 'all') {
        return [
          {
            label: 'วันที่สมัคร (วัน-เดือน-ปี)',
            field: row => this.$date(row.register_date).format('D-M-YYYY HH:mm'),
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'อีเมล / เบอร์โทรศัพท์',
            field: 'email',
          },
          {
            label: 'ชื่อร้าน',
            field: 'shop_name',
          },
          {
            label: 'ชื่อ นามสกุล',
            field: 'full_name',
          },
          {
            label: 'ยอดขาย',
            field: 'sale_total_amount',
            type: 'number',
          },
          {
            label: 'ยอดขายสุทธิ (หักส่วนต่างแล้ว)',
            field: 'sale_total_withholding',
            type: 'number',
          },
          {
            label: 'สถานะบัญชี',
            field: rowObj => {
              const { verify_setting_info, verify_agreement_info } = rowObj
              if (verify_setting_info.status === 'Approval' && verify_agreement_info.status === 'Approval') {
                return 'อนุมัติ'
              }

              if (verify_setting_info.status === 'Reject' || verify_agreement_info.status === 'Reject') {
                return 'ไม่อนุมัติ'
              }

              return 'รออนุมัติ'
            },
            type: 'status',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
            renderVariant: ({ row }) => {
              // console.log('renderVariant', row)
              const { verify_setting_info, verify_agreement_info } = row

              if (verify_setting_info.status === 'Approval' && verify_agreement_info.status === 'Approval') {
                return 'success'
              }

              if (verify_setting_info.status === 'Reject' || verify_agreement_info.status === 'Reject') {
                return 'danger'
              }

              return 'secondary'
            },
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ]
      }

      return [
        {
          label: 'วันที่สมัคร (วัน-เดือน-ปี)',
          field: row => this.$date(row.register_date).format('D-M-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'email',
        },
        {
          label: 'ชื่อร้าน',
          field: 'shop_name',
        },
        {
          label: 'ชื่อ นามสกุล',
          field: 'full_name',
        },
        {
          label: 'เอกสารยืนยันตัวตน',
          field: rowObj => this.statusSettingInfoText[rowObj.verify_setting_info.status],
          type: 'status',
          renderVariant: ({ row }) => {
            const { status } = row.verify_setting_info
            if (status === 'Approval') return 'success'
            if (status === 'Reject') return 'danger'

            return 'secondary'
          },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'เอกสารสัญญา',
          field: rowObj => this.statusAgreementInfo[rowObj.verify_agreement_info.status],
          type: 'status',
          renderVariant: ({ row }) => {
            const { status } = row.verify_agreement_info
            if (status === 'Approval') return 'success'
            if (status === 'Reject') return 'danger'

            return 'secondary'
          },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      // await new Promise(r => setTimeout(r, 500))
      //  verify_agreement_info สถานะการตรวจสอบ เอกสารสัญญา ที่ส่งให้ partner
      //  verify_setting_info สถานะการตรวจสอบ เอกสารยืนยันตัวตน ที่ partner กรอกเข้ามา
      const resp = await this.api.get(this.url).catch(() => null)
      // console.log('[RES] getItems Table', resp)
      if (resp && Array.isArray(resp)) {
        this.items = [...resp]
      }

      this.loading = false
    },
    tableClickView(rowObj) {
      // console.log('tableClickView', rowObj)
      // partners-management-details
      const { user_id } = rowObj
      if (this.keyName === 'all') {
        this.$router.push({ name: 'partners-management-details', params: { id: user_id }, query: { tab: 'general' } })
      } else {
        this.$router.push({ name: 'partners-management-details', params: { id: user_id }, query: { tab: 'account' } })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
